import React, { useRef, useState } from 'react';
import { EllipsisSpan, Filter, Paging, Table, usePaging, Wrapper } from '@maxtropy/components';
import { Button, Col, DatePicker, Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import { useRequest, useUpdateEffect } from 'ahooks';

import DeleteModal from '../DeleteModal';
import {
  apiValueDelPageGet,
  ValueDelDelValuePostRequest,
  ValueDelPageGetRequest,
  ValueDelPageGetResponse,
} from '@maxtropy/device-mock-apis';
import { List, QueryData } from './listTypes';
import DeleteConfirmModal from '../DeleteModal/DeleteConfirmModal';
import DeleteDetailModal from '../DeleteDetailModal';
import styles from './index.module.scss';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const otherColumns = [
  {
    title: '租户名称',
    width: 100,
    dataIndex: 'tenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    width: 200,
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    width: 200,
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '属性',
    dataIndex: 'propertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '数据删除区间',
    ellipsis: { showTitle: true },
    width: 280,
    render: (v: List) => {
      return (
        <EllipsisSpan
          value={`${dayjs(v.dataDelStartTime).format(DATE_TIME_FORMAT)}-${dayjs(v.dataDelEndTime).format(
            DATE_TIME_FORMAT
          )}`}
        />
      );
    },
  },
  {
    title: '操作人',
    dataIndex: 'userName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'operatingTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(DATE_TIME_FORMAT)} />,
  },
];

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '模拟数据删除' }];

interface MockDataDeletionProps {}
const MockDataDeletion: React.FC<MockDataDeletionProps> = () => {
  const [form] = Form.useForm();
  const [deleteModalForm] = Form.useForm();
  const [deleteModalParams, setDeleteModalParams] = useState<ValueDelDelValuePostRequest>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<ValueDelPageGetRequest>();
  const [list, setList] = useState<ValueDelPageGetResponse['list']>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteDetailModalOpen, setDeleteDetailModalOpen] = useState(false);
  const deleteDetailId = useRef<number>(0);

  const onFinish = (values: QueryData) => {
    const filteredData = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== undefined && v !== ''));

    if (filteredData.time) {
      filteredData.startTime = dayjs(filteredData.time[0]).unix();
      filteredData.endTime = dayjs(filteredData.time[1]).unix();
      delete filteredData.time;
    }

    setSearchParams(filteredData);
    setPageOffset(1);
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams({});
    setPageOffset(1);
  };

  const onDeleteModalCancel = () => {
    modalApi.confirm({
      content: '您是否取消当前操作？',
      onOk() {
        setDeleteModalOpen(false);
      },
    });
  };

  const onDeleteModalOk = () => {
    deleteModalForm.validateFields().then(values => {
      const params = {
        ...values,
        deviceIdList: values.deviceIdList.map((i: any) => i.value),
        deviceTypeId: Number(values.deviceTypeId?.at(-1)),
        startTime: dayjs(values.date[0]).unix(),
        endTime: dayjs(values.date[1]).unix(),
      };
      delete params.date;
      setDeleteModalParams(params);
      setDeleteConfirmModalOpen(true);
    });
  };

  const { loading } = useRequest(
    () =>
      apiValueDelPageGet({
        page: pageOffset.toString(),
        size: pageSize.toString(),
        ...searchParams,
      }),
    {
      onSuccess: data => {
        setTotalCount(data.total as number);
        setList(data.list);
      },
      refreshDeps: [pageOffset, pageSize, searchParams, setTotalCount],
    }
  );

  useUpdateEffect(() => {
    deleteModalOpen && deleteModalForm.resetFields();
  }, [deleteModalOpen]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      render: (_record: List) => (
        <Button
          type="link"
          onClick={() => {
            deleteDetailId.current = _record.id;
            setDeleteDetailModalOpen(true);
          }}
        >
          详情
        </Button>
      ),
    },
  ];

  const filters = (
    <>
      <Filter form={form} onFinish={onFinish} onReset={onReset}>
        <Col span={6}>
          <Form.Item label="租户" name="tenantName">
            <Input placeholder="请输入租户名称搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="编号/名称" name="deviceCodeOrName">
            <Input placeholder="请输入编号/名称搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="操作人" name="userName">
            <Input placeholder="请输入操作人搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="操作时间" name="time">
            <DatePicker.RangePicker showTime className={styles.rangePicker} />
          </Form.Item>
        </Col>
      </Filter>
    </>
  );
  return (
    <>
      <Wrapper routes={routes} filters={filters} className={styles.wrapper}>
        <Button type="primary" className={styles.deleteBtn} onClick={() => setDeleteModalOpen(true)}>
          删除模拟数据
        </Button>
        <Table rowKey="id" columns={columns} loading={loading} dataSource={list} pagination={false} />
        <Paging pagingInfo={pagingInfo} />

        <Modal
          title="删除数采模拟数据"
          width={600}
          open={deleteModalOpen}
          onCancel={onDeleteModalCancel}
          onOk={onDeleteModalOk}
          destroyOnClose
          okText="删除"
          cancelText="取消"
        >
          <DeleteModal form={deleteModalForm} />

          <DeleteConfirmModal
            open={deleteConfirmModalOpen}
            setOpen={setDeleteConfirmModalOpen}
            data={deleteModalParams}
            onAfterDeleted={() => {
              setDeleteModalOpen(false);
              onReset();
            }}
          ></DeleteConfirmModal>
        </Modal>

        <DeleteDetailModal
          open={deleteDetailModalOpen}
          setOpen={setDeleteDetailModalOpen}
          id={deleteDetailId.current}
        ></DeleteDetailModal>
        {modalContextHolder}
      </Wrapper>
    </>
  );
};

export default MockDataDeletion;
