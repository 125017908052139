import React, { Key, Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Paging, Table, usePaging } from '@maxtropy/components';
import Filter from '@/shared/components/Filter';
import { Space, Form, Input } from 'antd';
import { useQuery } from '../../utils/utils';
import { DataAcquisitionFormProps } from '../../components/DataAcquisitionForm';
import styles from './index.module.scss';
import { DevicePageRequest, DevicePageResponse, getNotConfiguredEdgeDevicePage } from '../../api/edgeDevice';
import { Link } from 'react-router-dom';

function formatListData(response: DevicePageResponse): Array<TableData> {
  return response.list.map(item => ({
    id: item.id,
    code: item.code,
    rootName: item.tenantCode + '+' + item.rootFullName,
    name: item.name,
    typeName: item.typeName,
  }));
}

type SearchParams = Omit<DevicePageRequest, 'page' | 'size'>;

type FilterParams = Omit<SearchParams, 'deviceType'>;

export interface DeviceModalRef {
  selectedRowKeys: Array<Key> | undefined;
}

interface TableData {
  id: number;
  code: string;
  rootName: string;
  name: string;
  typeName: string;
}

interface DeviceModalProps extends DataAcquisitionFormProps {
  deviceIds: Array<number> | undefined;
}

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
  },
  {
    title: '设备名称',
    dataIndex: 'name',
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    width: 300,
  },
  {
    title: '所属租户',
    dataIndex: 'rootName',
  },
];

const DeviceModal = React.forwardRef(({ state, deviceIds }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const pagingInfo = usePaging(50);
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key> | undefined>(deviceIds);
  const { deviceType: deviceTypeArray, rootMcid, objectModalType } = state;
  const deviceType = deviceTypeArray?.slice(-1);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    setPageOffset(1);
    setSearchParams({ ...val, deviceType });
  };

  const onSelectChange = (rowKeys: Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  useImperativeHandle(ref, () => ({
    selectedRowKeys,
  }));

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getNotConfiguredEdgeDevicePage({
          ...searchParams,
          deviceType,
          rootMcid,
          physicalModelId: objectModalType,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  return (
    <div className={styles.deviceModal}>
      <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
        <Space wrap className={styles.space}>
          <Form.Item name="name" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
          {/*<div className={styles.display}>*/}
          {/*  <label>所属类目:</label>*/}
          {/*  <Cascader style={{width: 400}}*/}
          {/*            fieldNames={{children: 'child'}}*/}
          {/*            disabled bordered={false} value={deviceType}*/}
          {/*            options={formatOptionData(deviceTypeData)} className={styles.disabled}/>*/}
          {/*</div>*/}
        </Space>
      </Filter>
      <div className={styles.table_text}>
        <div className={styles.tips}>
          <span>已选择</span>
          <span className={styles.red}>{selectedRowKeys?.length || 0}</span>
          <span>项</span>
        </div>
        <div className={styles.action}>
          <span className={styles.text}>点击前往</span>
          <Link to={'/device/manage/device/create'} className={styles.href}>
            添加设备
          </Link>
        </div>
      </div>
      <Table<TableData>
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
});

export default DeviceModal;
