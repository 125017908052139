import { FC, useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  apiValueDelDelValuePost,
  apiValueDelValidCodeGet,
  ValueDelDelValuePostRequest,
} from '@maxtropy/device-mock-apis';
import styles from './index.module.scss';
import { useCurrent } from '@/components/Layout/hooks';

type RequestWithoutValidCode = Omit<ValueDelDelValuePostRequest, 'validCode'>;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: RequestWithoutValidCode | undefined;
  onAfterDeleted: () => void;
}

const DeleteConfirmModal: FC<Props> = props => {
  const { open, setOpen, data, onAfterDeleted } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const current = useCurrent();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const onOk = () => {
    form.validateFields().then(() => {
      setLoading(true);
      apiValueDelDelValuePost({
        ...(data as RequestWithoutValidCode),
        validCode: form.getFieldValue('validCode'),
      })
        .then(res => {
          if (!res) {
            messageApi.error('验证码不正确');
            return;
          }
          onCancel();
          onAfterDeleted && onAfterDeleted();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <Modal title="删除提示" destroyOnClose open={open} onCancel={onCancel} onOk={onOk} confirmLoading={loading}>
        <div className={styles.deleteConfirmModal}>
          <ExclamationCircleOutlined className={styles.icon} />

          <div>
            <div>您确定删除选定设备下对应时段内的数据么，数据删除后将不能恢复，并会记录操作日志。</div>
            <Form form={form} style={{ marginTop: 30 }}>
              <Form.Item label="手机号">
                <div>{current?.cellphone}</div>
              </Form.Item>

              <Form.Item name="validCode" label="验证码" required={false} rules={[{ required: true, len: 6 }]}>
                <Input style={{ width: 200 }} maxLength={6} suffix={<GetValidCode />}></Input>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      {contextHolder}
    </>
  );
};

const totalCountdown = 60;
const GetValidCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  const getValidCode = () => {
    setLoading(true);
    apiValueDelValidCodeGet({})
      .then(() => {
        setCountdown(totalCountdown);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className={styles.loading}>发送中</div>
      ) : countdown ? (
        <div className={styles.countDown}>{countdown}秒后可重发</div>
      ) : (
        <div className={styles.sendValidCodeBtn} onClick={getValidCode}>
          获取验证码
        </div>
      )}
    </>
  );
};

export default DeleteConfirmModal;
