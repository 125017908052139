import React, { FC, useEffect, useState } from "react";
import { Button, Form, Modal, Spin, Tag } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  apiValueDelDetailIdGet,
  ValueDelDetailIdGetResponse,
} from "@maxtropy/device-mock-apis";
import { getRealUrl } from "@maxtropy/components";
import dayjs from "dayjs";
import styles from "./index.module.scss";

const { Item } = Form;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

enum STATUS {
  PROCESSING = 0,
  FINISHED = 1,
  FAILED = 2,
}

const statusLabelMap = {
  [STATUS.PROCESSING]: "进行中",
  [STATUS.FINISHED]: "已完成",
  [STATUS.FAILED]: "失败",
};

const statusColorMap = {
  [STATUS.PROCESSING]: "processing",
  [STATUS.FINISHED]: "success",
  [STATUS.FAILED]: "error",
};

const statusIconMap = {
  [STATUS.PROCESSING]: <ClockCircleOutlined />,
  [STATUS.FINISHED]: <CheckCircleOutlined />,
  [STATUS.FAILED]: <CloseCircleOutlined />,
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: number;
}

const DeleteDetailModal: FC<Props> = (props) => {
  const { open, setOpen, id } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<ValueDelDetailIdGetResponse>();

  useEffect(() => {
    if (!open) {
      setDetailData(undefined);
    }

    if (open && id) {
      setLoading(true);
      apiValueDelDetailIdGet({ id: String(id) })
        .then((res) => {
          if (res.id !== id) return;
          setDetailData(res);
        })
        .finally(() => setLoading(false));
    }
  }, [open, id]);

  const onDownload = () => {
    window.open(getRealUrl(detailData?.key));
  };

  return (
    <Modal
      title="删除数据详情"
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      <Spin spinning={loading}>
        <Form layout="vertical" className={styles.form}>
          <Tag
            className={styles.status}
            color={statusColorMap[detailData?.status as STATUS]}
          >
            <span>{statusLabelMap[detailData?.status as STATUS]}</span>
            {statusIconMap[detailData?.status as STATUS]}
          </Tag>
          <Item label="选择租户">
            <div>{detailData?.tenantName}</div>
          </Item>
          <Item label="选择类目">
            <div>{detailData?.deviceTypeName}</div>
          </Item>
          <Item label="选择设备">
            <div>{detailData?.deviceName}</div>
          </Item>
          <Item label="选择属性">
            <div>{detailData?.propertyName}</div>
          </Item>
          <Item label="数据删除区间">
            <div>
              {`${dayjs(detailData?.dataDelStartTime as string).format(
                dateFormat
              )} ~ ${dayjs(detailData?.dataDelEndTime as string).format(
                dateFormat
              )}`}
            </div>
          </Item>
          <Item label="删除数据">
            <Button type="link" onClick={onDownload}>
              下载
            </Button>
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DeleteDetailModal;
