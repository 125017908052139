import React, { useContext, useEffect, useRef } from 'react';
import { Modal, Form, Input, Radio, Select, Row, Col, message } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import { DataPointType, DataProperty, DataPropertyType } from '../../../../../types';
import { PointContext } from '../../../contextTypes';
import { useDataProperties } from '../hooks';
import { EdgeDeviceTemplatePoint } from './index';
import FormSubmit from '../../FormSubmit';
import { checkNum, checkNumCoefficient, identifierValidatorBuilder, limitedIntegerValidatorBuilder } from '../entity';
import EnumMapping, { EditableTableRef } from '../components/EnumMapping';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';
import { InputNumber } from '@maxtropy/components';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export interface PointFormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties?: number[];
  usedIdentifier?: string[];
  dataPropertiesAll?: DataProperty[];
}

const PointFormDialog: React.FC<PointFormDialogProps> = ({
  row,
  dataPropertiesAll,
  usedProperties,
  usedIdentifier,
  onCancel,
  onOk,
}) => {
  const { setRow } = useContext(PointContext);
  const tableRef = useRef<EditableTableRef | null>(null);
  const [form] = Form.useForm();
  const valueFilterMode = Form.useWatch('valueFilterMode', form);

  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    await tableRef.current?.validate?.();
    if (tableRef.current?.isValiDataSameValue !== undefined || tableRef.current?.isValidRequire !== undefined) {
      if (!tableRef.current?.isValidRequire || !tableRef.current?.isValiDataSameValue) {
        return;
      }
    }
    const values = await form.validateFields();
    if (shouldContinue) {
      await onOk({
        ...values,
        k: values.k ?? 1,
        b: values.b ?? 0,
        enumMapping: tableRef.current?.tempObj,
      });
      form.resetFields();
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
        commonAddress: undefined,
        informationAddress: undefined,
      });
    } else {
      await onOk({
        ...values,
        k: values.k ?? 1,
        b: values.b ?? 0,
        enumMapping: tableRef.current?.tempObj,
      });
      onCancel();
    }
  };

  const IsEnumMapping = Form.useWatch('IsEnumMapping', form);

  return (
    <Modal
      width={600}
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <Form {...formLayout} form={form} labelWrap={true} initialValues={row} onFinish={onOk}>
        <Form.Item noStyle name="dataPropertyName" />
        <Form.Item noStyle name="physicalUnitGeneralName" />
        <Form.Item
          name="hasProperty"
          label="采集点类型"
          initialValue={true}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group disabled={!isNil(row?.id)}>
            <Radio value={true}>建模采集点</Radio>
            <Radio value={false}>非建模采集点</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle dependencies={['hasProperty']}>
          {({ getFieldValue }) =>
            getFieldValue('hasProperty') ? (
              <>
                <Form.Item
                  name="dataPropertyId"
                  label="数据属性"
                  rules={[{ required: true, message: '请选择数据属性' }]}
                >
                  <Select
                    onChange={value => {
                      form.setFieldsValue({
                        dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                      });
                      setDataPropertyId(value as number);
                      form.setFieldsValue({
                        physicalUnitId: undefined,
                        physicalUnitGeneralName: '',
                      });
                    }}
                    placeholder="请输入/选择"
                    showSearch
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataProperties?.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {dataPropertyType === DataPropertyType.YC && (
                  <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                        });
                      }}
                      placeholder="请选择"
                    >
                      {units?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.generalName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : (
              <Form.Item
                required
                name="identifier"
                label="非建模采集点标识符"
                rules={[
                  {
                    validator: identifierValidatorBuilder(usedIdentifier),
                  },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          name="writable"
          label="是否可写"
          initialValue={false}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="commonAddress"
          label="公共地址"
          rules={[{ required: true, message: '请输入' }, { validator: limitedIntegerValidatorBuilder(65534, 1) }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Item
          name="informationAddress"
          label="信息体地址"
          rules={[{ required: true, message: '请输入' }, { validator: limitedIntegerValidatorBuilder(16777215, 1) }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>

        {/* 枚举值映射 */}
        {dataPropertyType === DataPropertyType.ENUM && (
          <>
            <Form.Item
              name="IsEnumMapping"
              label="枚举映射"
              rules={[{ required: true, message: '请选择是否枚举映射' }]}
              initialValue={row?.enumMapping !== undefined ? (row?.enumMapping === null ? false : true) : true}
            >
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>
            {/* 枚举映射框 */}
            <Form.Item noStyle dependencies={['IsEnumMapping', 'dataPropertyId']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('IsEnumMapping') === true && (
                    <Form.Item name="enumMapping" noStyle>
                      <EnumMapping ref={tableRef} dataProperties={dataProperties} />
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
          </>
        )}

        <Form.Item noStyle dependencies={['IsEnumMapping']} shouldUpdate>
          {({ getFieldValue }) => {
            return (
              !IsEnumMapping && (
                <>
                  <Form.Item
                    name="k"
                    label="乘积系数k"
                    tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                    initialValue={1}
                    rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                  >
                    <Input placeholder="请输入-999999999~999999999的数字" />
                  </Form.Item>
                  <Form.Item
                    name="b"
                    label="偏移系数b"
                    tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                    initialValue={0}
                    rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                  >
                    <Input placeholder="请输入-999999999~999999999的数字" />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item
          name="valueFilterMode"
          label="数值过滤模式"
          initialValue={0}
          rules={[{ required: true, message: '请选择' }]}
          shouldUpdate
        >
          <Radio.Group
            onChange={v => {
              if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                message.warning('新网关版本在3.7.30版本以上');
              }
            }}
          >
            {Object.entries(FilterModeDisplay).map(([k, v]) => (
              <Radio key={k} value={Number(k)}>
                {v}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {valueFilterMode === 1 && (
          <Form.Item
            label="数值合理区间"
            name="valueRange"
            dependencies={['valueFloor', 'valueCeiling']}
            rules={[
              form => ({
                validator: () => validatorValueRange(form),
              }),
            ]}
            tooltip="区间外数值将被直接滤除，视为无效数值"
          >
            <Row gutter={8}>
              <Col span={11}>
                <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={2}>--</Col>
              <Col span={11}>
                <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        )}
        {valueFilterMode === 2 && (
          <>
            <Form.Item
              label="增长率合理区间"
              name="valueRange"
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip="新网关版本在3.7.30版本以上"
            >
              <Row gutter={8}>
                <Col span={9}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={9}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={3}>/min</Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="valueFilterCnt"
              label="边缘侧异常数据过滤个数"
              initialValue={5}
              rules={[{ required: true, message: '请输入' }]}
            >
              <InputNumber
                placeholder="请输入"
                min={1}
                max={20}
                precision={0}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
          <Input placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PointFormDialog;
