import * as React from 'react';
import { Button, EllipsisSpan, MxColumnType } from '@maxtropy/components';
import { getRange } from '../../../../../utils/utils';
import { IEnum, map2Ary, map2Ary2 } from '../utils';
import { Space } from 'antd';
import { FilterModeDisplay } from '@/shared/const';

export const getColumns = (
  dataPropertiesAll?: any[],
  showMappingInfoFn?: (values: IEnum[]) => void,
  insertCols: any[] = []
): MxColumnType<any>[] => {
  return [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      showForce: true,
      showComplete: true,
      fixed: 'left',
      keyList: ['dataPropertyName', 'identifier'],
      ellipsis: { showTitle: true },
      width: 180,
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      fixed: 'left',
      render: (value: string) => <EllipsisSpan value={value} />,
    },
    ...insertCols,
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
    },
    {
      title: '公共地址',
      dataIndex: 'commonAddress',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.commonAddress} />,
    },
    {
      title: '信息体地址',
      dataIndex: 'informationAddress',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.informationAddress} />,
    },
    {
      title: '枚举映射',
      dataIndex: 'enumMapping',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { enumMapping } = record.parameters ?? {};
        if (enumMapping) {
          let findEnum = dataPropertiesAll?.find(item => item.id === record.dataPropertyId);
          if (findEnum && findEnum?.enumValue) {
            let map2Arylist = map2Ary(findEnum?.enumValue);

            let list = map2Ary2(enumMapping);
            map2Arylist.forEach(item => {
              let find = list.find(i => String(i.enum) === String(item.enum));
              if (find) {
                item.value = Number(find.value).toString(16);
              }
            });
            if (map2Arylist && map2Arylist.length > 0) {
              return (
                <Space className="action-buttons">
                  <Button type="link" onClick={() => showMappingInfoFn?.(map2Arylist)}>
                    查看
                  </Button>
                </Space>
              );
            }
          }
        }
        return '--';
      },
    },
    {
      title: '乘积系数k',
      dataIndex: 'k',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.k} />,
    },
    {
      title: '偏移系数b',
      dataIndex: 'b',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.b} />,
    },
    {
      title: '数据过滤模式',
      dataIndex: 'valueFilterMode',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        return (
          <EllipsisSpan
            value={FilterModeDisplay[record.parameters?.valueFilterMode as keyof typeof FilterModeDisplay]}
          />
        );
      },
    },
    {
      title: '数值合理区间',
      dataIndex: 'dataRange',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: { showTitle: true },
      render: (value: string) => <EllipsisSpan value={value} />,
    },
  ];
};
